<template>
  <div>
    <!--<bread-crumbs />-->
    <TripAiBot type="detail" />
    <v-card
      elevation="4"
      class="my-0 mt-3 tabs-card"
    >
      <!-- v-if="!readOnly" -->
      <v-tabs v-model="tab">
        <!-- @change="tabChanged(tab)" -->
        <!--  -->
        <div class="d-flex align-center">
          <v-btn
            color="dark"
            text
            small
            @click="redirectBack()"
          >
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
        </div>
        <!--  -->
        <v-tab
          v-for="item in tabItems"
          :key="item.tab"
        >
          {{ item.tab }}
        </v-tab>

        <!--Tab 1 : Trip Telematic Dashboard  -->
        <v-tab-item v-if="isPermission('vehicle-trip-details.dashboard')">
          <TripTelematicDashboard />
        </v-tab-item>

        <!--Tab 2 : Trip Over Views  -->
        <v-tab-item>
          <OverView />
        </v-tab-item>

        <!--Tab 3 : Trip Telematics  -->
        <v-tab-item>
          <TripTelematics />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>
<script>
export default {
  components: {
    //BreadCrumbs: () => import("@/layouts/core/BreadCrumbs.vue"),
    TripAiBot: () => import("../utils/TripAiBot.vue"),
    TripTelematicDashboard: () => import("../tabs/TripTelematicDashboard.vue"),
    OverView: () => import("../tabs/OverViews.vue"),
    TripTelematics: () => import("../tabs/TripTelematics.vue"),
  },

  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    tabItems() {
      return [
        ...(this.$admin.can("vehicle-trip-details.dashboard")
          ? [
              {
                tab: this.$t("dashboard"),
                content: "dashboard",
              },
            ]
          : []),
        { tab: this.$t("over_view"), content: "over-view" },
        ...(this.$admin.can("vehicle-trip-details.telematics")
          ? [
              {
                tab: this.$t("telematics"),
                content: "telematics",
              },
            ]
          : []),
      ];
    },
  },

  beforeDestroy() {
    this.$store.commit("vehicleTrips/SET_VEHICEL_TRIPS_DETAIL", {});
    window.removeEventListener("message", this.handleMapViewMessage);
  },
  async mounted() {},
  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },
    redirectBack() {
      this.$store.commit("vehicleTrips/SET_VEHICEL_TRIPS_DETAIL", {});
      const IMEI = this.$route.query.imei;
      return this.$router.push({
        name: "trip-listing",
        params: { imei: IMEI },
        query: this.$route.query,
      });
    },
  },
};
</script>
